import * as React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Spacer from "../components/spacer"
import Form from "../components/form"
import Seo from "../components/seo"

const ContactPage = () => {
  return (
  <Layout>
      <Seo title="Contact Us" />
      <div className='p20 max-1600 ma'>
        <div className='bg-blue white br-60 p40'>
          <Spacer className='m-small' />
          <div className='flex m-wrap'>
            <div className='w-50 m-100'>
              <Contact title='Contact us today.' blurb='To find out how Pulsi can help your business thrive, leave us your details and we will be in touch.'/>
            </div>
            <div className='w-50 m-100'>
              <Form />
            </div>
          </div>
          <Spacer className='m-small' />
        </div>
      </div>
  </Layout>
  )
}

export default ContactPage
